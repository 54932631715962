/* eslint-disable no-console */
import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';

class HttpClient {
  api: AxiosInstance;

  constructor() {
    this.api = axios.create({
      // baseURL: `https://api.church-managment-bff.cvmakers.com.br`,
      baseURL: `${process.env.REACT_APP_API}`,
      timeout: 30000,
      timeoutErrorMessage: 'Tempo limite da requisição excedido.',
    });
    this.initInterceptors();
  }

  private initInterceptors() {
    this.api.interceptors.response.use(
      async (response: AxiosResponse) => {
        return response;
      },

      async (error: AxiosError) => {
        const objectError = {
          error: error?.response?.data || error,
          status: error.response?.status,
        };

        if (error && error.response && error.response.status === 401) {
          console.log('TOKEN EXPIRED, ENDING SESSION');

          console.log(objectError);
          // return Promise.reject(objectError);
        }

        return Promise.reject(objectError);
      },
    );
  }
}

export const { api } = new HttpClient();
