import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: #ebebed;
`;

export const ImgMain = styled.img`
  height: auto;
  width: 50%;
  margin-bottom: 15px;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 40vw;
  height: 100vh;

  div {
    width: 100%;
    button {
      margin-top: 0px;
    }
  }
  button {
    margin-top: 20px;
  }
  @media only screen and (max-width: 768px) {
    width: 80vw;
  }

  /* @media (max-width: 768px) {
    width: 80%;
    background: green !important;
  } */
`;
