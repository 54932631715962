import { Container } from './styles';

interface IProps {
  onClick?: any;
  children: any;
  disabled?: boolean;
}
const defaultProps = {
  onClick: null,
  disabled: false,
};

const ButtonDashboard: React.FC<IProps> = ({
  onClick,
  children,
  disabled,
}: IProps) => {
  return (
    <Container disabled={disabled} onClick={onClick}>
      {children}
    </Container>
  );
};

ButtonDashboard.defaultProps = defaultProps;

export default ButtonDashboard;
