// import { FaChurch } from 'react-icons/fa';
import { IoPeopleSharp } from 'react-icons/io5';
import { HiOutlineDocumentReport } from 'react-icons/hi';
import { IoMdExit, IoIosList } from 'react-icons/io';
// import { GiTeacher } from 'react-icons/gi';

import { useHistory } from 'react-router-dom';
import { RiLockPasswordFill } from 'react-icons/ri';
import { ButtonDashboard, Header } from '~/components';
import { usePageContext } from '~/hooks';
import { Container, Content } from './styles';

const Home: React.FC = () => {
  const history = useHistory();
  const { setIsLogged, profileId, churchId } = usePageContext();

  return (
    <Container>
      <Header />
      <Content>
        {profileId !== 22 && (
          <>
            <ButtonDashboard onClick={() => history.push('/frequencia')}>
              <IoPeopleSharp />
              Cadastrar Frequência
            </ButtonDashboard>
          </>
        )}
        <ButtonDashboard onClick={() => history.push('/relatorios')}>
          <HiOutlineDocumentReport />
          Relatórios
        </ButtonDashboard>
        <ButtonDashboard onClick={() => history.push('/cadastros')}>
          <IoIosList />
          Ver Cadastros
        </ButtonDashboard>
        {churchId === '7' && (
          <ButtonDashboard onClick={() => history.push('/professores')}>
            <IoPeopleSharp />
            Cadastro de Professores
          </ButtonDashboard>
        )}
        <ButtonDashboard onClick={() => history.push('/senha')}>
          <RiLockPasswordFill />
          Alterar a senha
        </ButtonDashboard>
        <ButtonDashboard onClick={() => setIsLogged(false)}>
          <IoMdExit />
          Sair
        </ButtonDashboard>
      </Content>
    </Container>
  );
};

export default Home;
