import { api } from './api';

interface IUpdatePassword {
  actualPassword: string;
  password: string;
}

interface ICreateTeacher {
  churchId: string;
  name: string;
  phone: string;
  education: string;
  dateOfBirth: Date;
  classe: string;
  higherLevel: string;
  mastersDegree: string;
  doctorateDegree: string;
  postgraduate: string;
}

class UserService {
  async updatePassword({
    password,
    actualPassword,
  }: IUpdatePassword): Promise<any> {
    const response = await api.patch(`/bs/users/password/me`, {
      password,
      actualPassword,
    });

    return response.data;
  }

  async createTeacher({
    churchId,
    name,
    phone,
    education,
    dateOfBirth,
    classe,
    higherLevel,
    mastersDegree,
    doctorateDegree,
    postgraduate,
  }: ICreateTeacher): Promise<any> {
    const response = await api.post(`/bs/teachers`, {
      church_id: churchId,
      name,
      phone,
      education,
      date_of_birth: dateOfBirth,
      classe,
      higher_level: higherLevel,
      masters_degree: mastersDegree,
      doctorate_degree: doctorateDegree,
      postgraduate,
    });

    return response.data;
  }

  async listTeacher(): Promise<any> {
    const response = await api.get(`/bs/teachers/me`);
    return response.data;
  }

  async deleteTeacher(id: string): Promise<any> {
    const response = await api.delete(`/bs/teachers/${id}`);
    return response.data;
  }

  async sendTeacher(): Promise<any> {
    const response = await api.post(`/bs/teachers/confirm`);
    return response.data;
  }
}

export default new UserService();
