import styled from 'styled-components';

export const Content = styled.div`
  margin-top: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  svg {
    margin-bottom: 10px;
    width: 20%;
    height: auto;
  }

  /* b {
    font-size: 1.3rem;
    line-height: 22px;
    text-align: center;
  } */

  p {
    font-size: 1rem;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
  }
`;

export const TitleModal = styled.div`
  b {
    font-size: 20px;
  }
  svg {
    height: auto;
    width: 2vh;
    color: #488cfa;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -2vh;
`;

export const AlertText = styled.div`
  padding: 15px 10px;
  border-radius: 10px;
  background: rgba(255, 253, 130, 0.5);

  margin: 10px 0px;

  div {
    width: 100%;
    text-align: center;
  }
`;

export const ContainerTextArea = styled.div`
  margin: 10px 0px 25px 0px;
  width: 100%;
`;

export const TextArea = styled.textarea`
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  background: #fcfcfc;
  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  color: rgba(0, 0, 0, 0.5);
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
`;
