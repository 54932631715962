import { api } from './api';
import {
  UPDATE_CLASSES_REQUEST_YEAR,
  UPDATE_CLASSES_REQUEST_QUARTER,
} from '../constants';

interface ILogin {
  email: string;
  password: string;
}

class AuthService {
  async login({ email, password }: ILogin): Promise<any> {
    const response = await api.post(`/bs/sessions`, {
      email,
      password,
    });

    return response.data;
  }

  async validateUpdate(): Promise<any> {
    const year = UPDATE_CLASSES_REQUEST_YEAR;
    const quarter = UPDATE_CLASSES_REQUEST_QUARTER;

    const response = await api.get(
      `/bs/update-classes-request/year/${year}/quarter/${quarter}`,
    );

    return response.data;
  }

  async getVersion(): Promise<any> {
    const response = await api.get(`/bs/version`);

    return response.data;
  }
}

export default new AuthService();
