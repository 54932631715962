import styled from 'styled-components';

export const Container = styled.div`
  text-align: center;
  color: #323232;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
`;

export const ContainerClasses = styled.div`
  max-height: 85vh;
  padding: 0 10px;
  overflow-y: scroll;
`;

export const Title = styled.h1`
  text-align: center;
  color: #323232;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  margin-bottom: 15px;
`;

export const Text = styled.p`
  text-align: center;
  color: #323232;
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-size: 15px;

  b {
    font-weight: 500;
    color: #323232;
  }
`;

export const Select = styled.select`
  border-radius: 5px;

  height: 45px;
  padding: 15px;

  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  background: #fcfcfc;
  color: rgba(0, 0, 0, 0.5);
`;

export const Question = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  margin: 20px 0;

  button {
    margin: 0px;
  }
`;

export const Margin = styled.div`
  height: 15px;
  display: block;
`;
