import styled from 'styled-components';

export const SelectYear = styled('select')`
  border-radius: 10px;
  height: 45px;
  padding: 8px;
  width: 150px;
  border: solid 1px;
  background: #fcfcfc;
  color: rgba(0, 0, 0, 0.5);
`;
