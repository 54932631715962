import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 15px 0px;
  width: 90%;
  padding: 15px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;

  font-size: 1rem;

  button {
    margin-top: 15px;
  }
  span + span {
    margin-top: 0px;
  }
  span {
    margin: 20px 0;
    div {
      width: 100%;
    }
    button {
      margin-top: 0px;
    }
  }
`;

export const ParsonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px;
  @media (min-width: 600px) {
    flex-direction: column;
    align-items: flex-start;
    button {
      width: 100% !important;
      margin-top: 10px !important;
    }
  }
  button {
    width: 20%;
    min-width: fit-content;
    background-color: #fbab7e;
  }
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin: 20px 0px;
`;

export const SubTitle = styled.h6`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-size: 14px;
  margin: 10px 0px;
`;

export const Select = styled('select')<{ error: boolean }>`
  border-radius: 5px;

  height: 45px;
  padding: 0px 15px;
  margin-bottom: 10px;

  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  background: #fcfcfc;
  color: rgba(0, 0, 0, 0.5);
  ${props => props.error && 'border: 1px solid #DF0A24;'}
`;

export const ErroAlert = styled.p`
  margin-top: 4px;
  color: #df0a24;
  font-size: 10px;
  min-height: 16px;
  margin-top: -20px;
`;

export const TextArea = styled.textarea`
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  background: #fcfcfc;
  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  color: rgba(0, 0, 0, 0.5);
  margin-top: 10px;
`;

export const DateArea = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 15px;
  margin-bottom: 20px;

  cursor: pointer;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  color: rgba(0, 0, 0, 0.5);
  svg {
    color: black;
  }
`;

export const Label = styled.label`
  display: flex;
  flex-direction: column;
  padding: 6px 0;
  font-weight: 300;
`;
