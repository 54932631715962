import React, { useEffect, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { Button, Header, Loading, Return, TablePage } from '~/components';
import userService from '~/services/userService';
import { Margin } from '~/components/ModalUpdate/styles';
import { usePageContext } from '~/hooks';
import { Container, Content, ShareButton, Title } from './styles';

const Reports: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [dataSource, setDataSource] = useState<any>([]);
  const { churchId } = usePageContext();

  const loadChurchs = async () => {
    setLoading(true);
    const res = await userService.listTeacher();
    setDataSource(res.result);
    setLoading(false);
  };

  const deleteTeacher = async (id: string) => {
    setLoading(true);
    await userService.deleteTeacher(id);
    loadChurchs();
    setLoading(false);
  };

  const sendTeacher = async () => {
    setLoading(true);
    await userService.sendTeacher();
    loadChurchs();
    setLoading(false);
  };

  useEffect(() => {
    loadChurchs();
  }, []);

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Classe',
      dataIndex: 'classe',
      key: 'classe',
    },
    {
      key: 'actions',
      width: '60px',
      render: (e: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ width: '10px' }} />
          <button
            style={{ margin: '0', display: 'flex', alignItems: 'center' }}
            type="button"
            onClick={() => {
              deleteTeacher(e.id);
            }}
          >
            <MdDelete />
          </button>
        </div>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Container>
        <Header />
        <Return />
        <Content>
          <Title>Cadastros de profesores</Title>
          <Margin />
          <ShareButton
            href={`https://api.whatsapp.com/send?text=https://ebd.adpb.org.br/cadastro/${btoa(
              churchId,
            )}`}
            target="_blank"
          >
            Compartilhar Link
          </ShareButton>
          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey="id"
          />
          <Button onClick={sendTeacher}>Enviar</Button>
        </Content>
      </Container>
    </>
  );
};

export default Reports;
