/* eslint-disable react-hooks/exhaustive-deps */
import { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Button, Header, Input, Loading } from '~/components';
import {
  generateDefaultInputValues,
  generateRequiredInputValues,
  isEmpty,
  validateForm,
  maskPhone,
  convertToTimestamp,
} from '~/utils';
import ModalDefalut from '~/components/ModalDefault';
import userService from '~/services/userService';

import { Container, Content, Label, Select, Title } from './styles';

interface IPropsInput {
  isValid: boolean;
  value: string;
  required: boolean;
  error: string;
}

type typeInput = {
  [key: string]: IPropsInput;
};

const TeacherRegistration: React.FC = () => {
  const { church_id } = useParams<{ church_id: string }>();
  const history = useHistory();

  const stateSchema = {
    ...generateRequiredInputValues([
      'name',
      'phone',
      'education',
      'classe',
      'dateOfBirth',
    ]),
    ...generateDefaultInputValues([
      'higherLevel',
      'mastersDegree',
      'doctorateDegree',
      'postgraduate',
    ]),
  };

  const [inputState, setInputState] = useState<typeInput>(stateSchema);
  const [isVisibleModalErro, setIsVisibleModalErro] = useState(false);
  const [isVisibleModalSuccess, setIsVisibleModalSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [, setDisabled] = useState(false);
  const [MessageModalErro, setMessageModalErro] = useState(
    'Ops... algo deu errado!',
  );

  const levelOfEducation = [
    { name: 'Selecione', value: 0 },
    { name: 'Sem instrução formal', value: 1 },
    { name: 'Educação infantil', value: 2 },
    { name: 'Ensino fundamental incompleto', value: 3 },
    { name: 'Ensino fundamental completo', value: 4 },
    { name: 'Ensino médio incompleto', value: 5 },
    { name: 'Ensino médio completo', value: 6 },
    { name: 'Técnico/profissionalizante incompleto', value: 7 },
    { name: 'Técnico/profissionalizante completo', value: 8 },
    { name: 'Ensino superior incompleto', value: 9 },
    { name: 'Ensino superior completo', value: 10 },
    { name: 'Pós-graduação incompleta (Especialização e MBA)', value: 11 },
    { name: 'Pós-graduação completa (Especialização e MBA)', value: 12 },
    { name: 'Mestrado incompleto', value: 13 },
    { name: 'Mestrado completo', value: 14 },
    { name: 'Doutorado incompleto', value: 15 },
    { name: 'Doutorado completo', value: 16 },
    { name: 'Pós-doutorado', value: 17 },
  ];

  const classes = [
    'Selecione',
    'Classe Única (Senhores e Senhoras)',
    'Classe de Senhores',
    'Classe de Senhoras',
    'Classe de Novos Convertidos (Discipulado)',
    'Classe de Jovens',
    'Classe de Adolescentes',
    'Classe de Pré-Adolescentes',
    'Classe de Juvenis',
    'Classe de Juniores',
    'Classe de Primários',
    'Classe do Jardim da Infância',
    'Classe do Maternal',
    'Classe do Berçário',
  ];

  const handleInput = (value: string, inputName: string) => {
    let error = '';
    let isValid = true;

    if (inputName === 'classe') {
      let data = {};
      if (parseInt(inputState.education.value, 10) >= 9) {
        data = {
          higherLevel: {
            isValid: inputState.higherLevel.isValid,
            value: inputState.higherLevel.value,
            required: true,
            error: inputState.higherLevel.error,
          },
        };
        if (
          parseInt(inputState.education.value, 10) === 11 ||
          parseInt(inputState.education.value, 10) === 12
        ) {
          data = {
            ...data,
            postgraduate: {
              isValid: inputState.mastersDegree.isValid,
              value: inputState.mastersDegree.value,
              required: true,
              error: inputState.mastersDegree.error,
            },
          };
        }
        if (parseInt(inputState.education.value, 10) >= 13) {
          data = {
            ...data,
            mastersDegree: {
              isValid: inputState.mastersDegree.isValid,
              value: inputState.mastersDegree.value,
              required: true,
              error: inputState.mastersDegree.error,
            },
          };
        }
        if (parseInt(inputState.education.value, 10) >= 15) {
          data = {
            ...data,
            doctorateDegree: {
              isValid: inputState.doctorateDegree.isValid,
              value: inputState.doctorateDegree.value,
              required: true,
              error: inputState.doctorateDegree.error,
            },
          };
        }
      }

      return setInputState((prevState: any) => ({
        ...prevState,
        [inputName]: {
          isValid,
          value,
          required: inputState[inputName].required,
          error,
        },
        ...data,
      }));
    }

    if (inputState![inputName].required && value.length === 0) {
      error = 'campo obrigatorio';
      isValid = false;
    }

    return setInputState((prevState: any) => ({
      ...prevState,
      [inputName]: {
        isValid,
        value,
        required: inputState[inputName].required,
        error,
      },
    }));
  };

  const isFormValid = () => {
    const inputsWithError = validateForm(inputState);
    let hasError = false;

    Object.keys(inputState).forEach(inputValue => {
      if (inputState[inputValue].error) {
        hasError = true;
      }
    });

    if (parseInt(inputState.education.value, 10) === 0) {
      hasError = true;
    }

    if (
      parseInt(inputState.education.value, 10) >= 9 &&
      inputState.higherLevel.value.length < 1
    ) {
      hasError = true;
    }

    if (
      parseInt(inputState.education.value, 10) >= 11 &&
      inputState.postgraduate.value.length < 1
    ) {
      hasError = true;
    }

    if (
      parseInt(inputState.education.value, 10) >= 13 &&
      inputState.mastersDegree.value.length < 1
    ) {
      hasError = true;
    }

    if (
      parseInt(inputState.education.value, 10) >= 15 &&
      inputState.doctorateDegree.value.length < 1
    ) {
      hasError = true;
    }

    return isEmpty(inputsWithError) && !hasError;
  };

  const validAll = async () => {
    Object.entries(inputState).forEach(allInput => {
      if (
        allInput[0] !== 'observation' &&
        allInput[0] !== 'phone' &&
        allInput[0] !== 'name' &&
        allInput[0] !== 'date' &&
        !allInput[0].includes('justification')
      ) {
        if (
          allInput[1].value === '0' &&
          inputState[`justification${allInput[0]}`].value.length === 0
        ) {
          return setInputState((prevState: any) => ({
            ...prevState,
            [`justification${allInput[0]}`]: {
              isValid: false,
              value: inputState[`justification${allInput[0]}`].value,
              required: true,
              error: 'campo obrigatorio',
            },
          }));
        }
      }
      return null;
    });

    const create = async () => {
      if (isFormValid()) {
        try {
          setLoading(true);
          await userService.createTeacher({
            churchId: church_id,
            education:
              levelOfEducation.find(
                (e: any) =>
                  e.value === parseInt(inputState.education.value, 10),
              )?.name || '0',
            classe: inputState.classe.value,
            dateOfBirth: convertToTimestamp(inputState.dateOfBirth.value),
            name: inputState.name.value,
            phone: inputState.phone.value,
            higherLevel: inputState.higherLevel.value,
            mastersDegree: inputState.mastersDegree.value,
            doctorateDegree: inputState.doctorateDegree.value,
            postgraduate: inputState.postgraduate.value,
          });
          setIsVisibleModalSuccess(true);
        } catch (e: any) {
          if (e.status === 400) {
            setMessageModalErro(e.error.message);
          } else {
            setMessageModalErro('Ops... algo deu errado!');
          }
          setIsVisibleModalErro(true);
        }
        setDisabled(false);
        setLoading(false);
      }
    };

    create();
  };

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalErro && (
        <ModalDefalut
          success={false}
          onClick={() => setIsVisibleModalErro(false)}
        >
          {MessageModalErro}
        </ModalDefalut>
      )}
      {isVisibleModalSuccess && (
        <ModalDefalut success onClick={() => history.push('/home')}>
          Cadastro enviado com sucesso!
        </ModalDefalut>
      )}
      <Container>
        <Header />
        <Content>
          <Title>Cadastro de Professor</Title>
          <Input
            label="Nome completo:"
            value={inputState.name.value}
            error={inputState.name.error}
            onChange={e => handleInput(e.target.value, 'name')}
          />
          <Input
            label="Telefone:"
            value={inputState.phone.value}
            error={inputState.phone.error}
            onChange={e => handleInput(maskPhone(e.target.value), 'phone')}
            maxLenght={15}
          />
          <Input
            label="Data de nascimento:"
            value={inputState.dateOfBirth.value}
            error={inputState.dateOfBirth.error}
            onChange={e =>
              handleInput(
                e.target.value
                  .replace(/\D/g, '')
                  .replace(/(\d{2})(\d)/, '$1/$2')
                  .replace(/(\d{2})(\d)/, '$1/$2'),
                'dateOfBirth',
              )
            }
            maxLenght={10}
          />
          <Label>Classe:</Label>
          <Select
            error={!!inputState.classe.error}
            onChange={e => handleInput(e.target.value, 'classe')}
          >
            {classes.map(e => (
              <option value={e}>{e}</option>
            ))}
          </Select>
          <Label>Grau de instrução:</Label>
          <Select
            error={!!inputState.education.error}
            onChange={e => {
              handleInput(e.target.value, 'education');
            }}
          >
            {levelOfEducation.map(e => (
              <option value={e.value}>{e.name}</option>
            ))}
          </Select>
          {parseInt(inputState.education.value, 10) >= 9 && (
            <Input
              label="Curso Nível Superior:"
              value={inputState.higherLevel.value}
              error={inputState.higherLevel.error}
              onChange={e => handleInput(e.target.value, 'higherLevel')}
            />
          )}
          {parseInt(inputState.education.value, 10) >= 11 && (
            <Input
              label="Curso Pós-Graduação:"
              value={inputState.postgraduate.value}
              error={inputState.postgraduate.error}
              onChange={e => handleInput(e.target.value, 'postgraduate')}
            />
          )}
          {parseInt(inputState.education.value, 10) >= 13 && (
            <Input
              label="Curso Mestrado:"
              value={inputState.mastersDegree.value}
              error={inputState.mastersDegree.error}
              onChange={e => handleInput(e.target.value, 'mastersDegree')}
            />
          )}
          {parseInt(inputState.education.value, 10) >= 15 && (
            <Input
              label="Curso Doutorado:"
              value={inputState.doctorateDegree.value}
              error={inputState.doctorateDegree.error}
              onChange={e => handleInput(e.target.value, 'doctorateDegree')}
            />
          )}
          <Button
            onClick={() => {
              setDisabled(true);
              validAll();
            }}
            disabled={!isFormValid()}
          >
            Salvar
          </Button>
        </Content>
      </Container>
    </>
  );
};

export default TeacherRegistration;
