import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { usePageContext } from '~/hooks';

interface IProps extends RouteProps {
  isPrivate?: boolean;
  component: React.FC<any>;
}

const RouteWrapper: React.FC<IProps> = ({
  component: Component,
  isPrivate,
  ...rest
}) => {
  const { isLogged } = usePageContext();

  // eslint-disable-next-line no-constant-condition
  if (!isLogged && isPrivate) {
    return <Redirect to="/" />;
  }

  if (isLogged && !isPrivate) {
    return <Redirect to="/home" />;
  }

  return <Route {...rest} render={props => <Component {...props} />} />;
};

RouteWrapper.defaultProps = {
  isPrivate: false,
};

export default RouteWrapper;
