import styled from 'styled-components';

export const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  align-items: center;

  h1 {
    margin: 15px 0;
  }
`;

export const Content = styled.div`
  @media (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    width: 90%;
  }

  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
  padding: 35px 5px 15px;
`;
