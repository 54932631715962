import moment from 'moment';
import { Label, InputStyle, ErroAlert, InputContainer } from './styles';

export interface IPropsInput {
  name?: string;
  id?: string;
  idWidth?: string;
  label?: any;
  disabled?: boolean;
  error?: string | null;
  icon?: any | undefined;
  value?: string | number;
  type?: string;
  required?: boolean;
  requiredLabel?: boolean;
  placeholder?: string;
  maxLenght?: number | undefined;
  onChange: any;
  min?: string | undefined;
  max?: string | undefined;
}

const defaultProps = {
  name: '',
  id: '',
  idWidth: '',
  label: '',
  disabled: false,
  error: null,
  icon: null,
  value: undefined,
  type: '',
  required: false,
  requiredLabel: false,
  placeholder: '',
  maxLenght: undefined,
  onclick: null,
  onChange: null,
  onBlur: null,
  onKeyUp: null,
  min: undefined,
  max: undefined,
};

const DatePicker: React.FC<IPropsInput> = ({
  name,
  id,
  label,
  error,
  disabled,
  requiredLabel,
  placeholder,
  onChange,
  min,
  max,
}: IPropsInput) => {
  const disabledDate = (current: any) => {
    // Não permitir seleção de datas fora do intervalo 01-01-2024 a 01-02-2024
    return (
      current < moment(min, 'YYYY-MM-DD') || current > moment(max, 'YYYY-MM-DD')
    );
  };

  function addLabel() {
    if (label) {
      return (
        <Label htmlFor={id}>
          {requiredLabel ? (
            <div>
              <b>{label}</b> (Obrigatorio)
            </div>
          ) : (
            <b>{label}</b>
          )}
        </Label>
      );
    }
    return null;
  }

  return (
    <div style={{ width: '100%' }}>
      {addLabel()}
      <InputContainer>
        <InputStyle
          id={id}
          name={name}
          placeholder={placeholder}
          disabled={disabled}
          onChange={onChange}
          disabledDate={disabledDate}
          format="DD/MM/YYYY"
        />
      </InputContainer>
      <ErroAlert erro={!!error}>{error}</ErroAlert>
    </div>
  );
};

DatePicker.defaultProps = defaultProps;

export default DatePicker;
