import { useState, useEffect } from 'react';
import { RiCloseFill } from 'react-icons/ri';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { Modal, Button, DatePicker } from '~/components';
import { getDateOfWeek, getDay } from '~/utils';
import { usePageContext } from '~/hooks';
import {
  Content,
  TitleModal,
  AlertText,
  TextArea,
  ContainerTextArea,
  Label,
} from './styles';

interface IProps {
  lesson: { lesson: number; quarter: number };
  onSave: (inputDate: string, inputJustification: string) => void;
  onCloseModal: () => void;
  inputDate: string;
  setInputDate: (e: string) => void;
  inputJustification: string;
  setInputJustification: (e: string) => void;
}

const ModalDate: React.FC<IProps> = ({
  lesson,
  onSave,
  onCloseModal,
  inputDate,
  setInputDate,
  inputJustification,
  setInputJustification,
}: IProps) => {
  const [disabled, setDisabled] = useState(true);
  const { biblicalSchoolDay } = usePageContext();

  const dateRecommended = getDateOfWeek(
    lesson.lesson,
    lesson.quarter,
    getDay(biblicalSchoolDay),
  );

  useEffect(() => {
    if (
      new Date(inputDate).toLocaleDateString().length === 10 &&
      inputJustification.length > 9 &&
      inputJustification
    ) {
      return setDisabled(false);
    }
    return setDisabled(true);
  }, [inputDate, inputJustification]);

  return (
    <Modal>
      <>
        <TitleModal>
          <button type="button" onClick={onCloseModal}>
            <RiCloseFill />
          </button>
        </TitleModal>
        <Content>
          <AlertText>
            <div>
              <b>ATENCÃO!</b>
            </div>
            <br />
            Esse relatorio é referente a lição: <b>{lesson.lesson}</b> do{' '}
            <b>{lesson.quarter}º</b> trimestre. Tem certeza que deseja alterar a
            data da EBD?
            <br />
            <br />
            selecione uma data entre{' '}
            <b>
              {format(
                new Date(
                  getDateOfWeek(
                    lesson.lesson,
                    lesson.quarter,
                    getDay(biblicalSchoolDay),
                  ).setDate(dateRecommended.getDate() - 6),
                ),
                "dd 'de' MMMM 'de' y",
                { locale: pt },
              )}{' '}
            </b>
            e{' '}
            <b>
              {format(
                new Date(
                  getDateOfWeek(
                    lesson.lesson,
                    lesson.quarter,
                    getDay(biblicalSchoolDay),
                  ).setDate(dateRecommended.getDate() + 6),
                ),
                "dd 'de' MMMM 'de' y",
                { locale: pt },
              )}
            </b>
          </AlertText>
          <DatePicker
            label="Data da EBD"
            onChange={(e: any) => {
              setInputDate(e);
            }}
            min={
              new Date(
                getDateOfWeek(
                  lesson.lesson,
                  lesson.quarter,
                  getDay(biblicalSchoolDay),
                ).setDate(dateRecommended.getDate() - 6),
              )
                .toISOString()
                .split('T')[0]
            }
            max={
              new Date(
                getDateOfWeek(
                  lesson.lesson,
                  lesson.quarter,
                  getDay(biblicalSchoolDay),
                ).setDate(dateRecommended.getDate() + 7),
              )
                .toISOString()
                .split('T')[0]
            }
          />
          <ContainerTextArea>
            <Label>
              <b>Justificativa</b>
            </Label>
            <TextArea
              placeholder="Justifique aqui o motivo da mudança na data da EBD"
              value={inputJustification}
              onChange={e => {
                setInputJustification(e.target.value);
              }}
            />
            {inputJustification.length < 10 && (
              <p style={{ color: '#9b111e' }}>Insira no mínimo 10 caracteres</p>
            )}
          </ContainerTextArea>

          <Button
            disabled={disabled}
            onClick={() => {
              onSave(inputDate, inputJustification);
              onCloseModal();
            }}
          >
            Alterar data
          </Button>
        </Content>
      </>
    </Modal>
  );
};

export default ModalDate;
