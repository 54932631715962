import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import pt from 'date-fns/esm/locale/pt/index.js';
import { IoEyeSharp } from 'react-icons/io5';
import { BsCalendarX } from 'react-icons/bs';
import { Header, Loading, Return, TablePage } from '~/components';
import frequencyService from '~/services/frequencyService';
import { CURRENT_YEAR } from '~/constants';

import ModalDetails from './ModalDetails';
import {
  Container,
  Content,
  ResultSection,
  SelectQuarter,
  SelectYear,
  Title,
} from './styles';

const AttendanceList: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [isVisibleModalInfo, setIsVisibleModalInfo] = useState(false);
  const [handleIdInfo, setHandleIdInfo] = useState([]);

  const [dataSource, setDataSource] = useState<any>([]);

  const [selectedYear, setSelectedYear] = useState(CURRENT_YEAR);
  const [selectedQuarter, setSelectedQuarter] = useState('4');

  const fetchData = async (year?: string, quarter?: string) => {
    setLoading(true);
    const res = await frequencyService.ListFrequency(year, quarter);
    setDataSource(res.result);
    setLoading(false);
  };

  useEffect(() => {
    fetchData(selectedYear, selectedQuarter);
  }, [selectedYear, selectedQuarter]);

  const columns = [
    {
      title: 'Data',
      dataIndex: 'date',
      key: 'date',
      render: (e: any) =>
        `${e && format(new Date(e), "dd 'de' MMMM 'de' y", { locale: pt })}`,
    },
    {
      title: 'Presentes',
      dataIndex: 'totalPresence',
      key: 'totalPresence',
    },
    {
      key: 'frequencyClasse',
      width: '60px',
      render: (e: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {e.is_out_of_date && <BsCalendarX />}
          <div style={{ width: '10px' }} />
          <button
            style={{ margin: '0', display: 'flex', alignItems: 'center' }}
            type="button"
            onClick={() => {
              setHandleIdInfo(e);
              setIsVisibleModalInfo(true);
            }}
          >
            <IoEyeSharp />
          </button>
        </div>
      ),
    },
  ];

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {isVisibleModalInfo && (
        <ModalDetails
          onClick={() => setIsVisibleModalInfo(false)}
          list={handleIdInfo}
        />
      )}
      <Container>
        <Header />
        <Return />
        <Content>
          <Title>Cadastros de frequências</Title>
          <br />
          <ResultSection>
            <SelectQuarter
              name="select"
              value={selectedQuarter}
              onChange={e => setSelectedQuarter(e.target.value)}
            >
              <option value="1">1 trimestre</option>
              <option value="2">2 trimestre</option>
              <option value="3">3 trimestre</option>
              <option value="4">4 trimestre</option>
              <option value="">Todos</option>
            </SelectQuarter>

            <SelectYear
              name="select"
              value={selectedYear}
              onChange={e => setSelectedYear(e.target.value)}
            >
              <option value="2024">2024</option>
              <option value="2023">2023</option>
            </SelectYear>
          </ResultSection>

          <TablePage
            columns={columns}
            dataSource={dataSource}
            pagination={false}
            rowKey="id"
          />
        </Content>
      </Container>
    </>
  );
};

export default AttendanceList;
