import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  height: 8vh;
  padding: 0 20px;

  box-shadow: 0px 2px 7px rgba(0, 0, 0, 0.25);
  background-color: #fed032;
  color: #ffffff;

  h1 {
    margin: 0;
  }

  img {
    height: 50%;
  }
  button {
    margin-right: 10px;
    font-size: clamp(12px, 1.6vh, 24px);
    color: #323232;
  }
`;
