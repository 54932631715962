import React from 'react';
import { useHistory } from 'react-router-dom';
import { IoMdArrowRoundBack } from 'react-icons/io';
import { Button, Container } from './styles';

const Return: React.FC = () => {
  const history = useHistory();

  return (
    <Container>
      <Button
        onClick={() => {
          if (history.length > 1) {
            history.goBack();
          } else {
            history.replace('/home');
          }
        }}
        type="button"
      >
        <IoMdArrowRoundBack />
      </Button>
    </Container>
  );
};

export default Return;
