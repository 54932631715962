import { Switch } from 'antd';
import 'antd/dist/antd.css';

interface IProps {
  onChange: (e: boolean) => void;
  value?: boolean | undefined;
}

const defaultProps = {
  value: false,
};

const Toggle: React.FC<IProps> = ({ onChange, value }: IProps) => {
  return <Switch checked={value} onChange={e => onChange(e)} />;
};

Toggle.defaultProps = defaultProps;

export default Toggle;
