import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Routes from '~/routes';
import GlobalStyle from '~/styles/global';
import PageProvider from '~/context/PageContext';
import { ToastContainer } from 'react-toastify';
import { store } from './store';

const App: React.FC = () => (
  <Provider store={store}>
    <BrowserRouter>
      <PageProvider>
        <Routes />
        <GlobalStyle />
        <ToastContainer autoClose={5000} />
      </PageProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
