import React from 'react';
import { SelectQuarter } from './styled';

interface SelectQuarterComponentProps {
  selectedQuarter: string;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

const SelectQuarterComponent: React.FC<SelectQuarterComponentProps> = ({
  selectedQuarter,
  onChange,
}) => {
  return (
    <SelectQuarter name="select" value={selectedQuarter} onChange={onChange}>
      <option value="1">1º trimestre</option>
      <option value="2">2º trimestre</option>
      <option value="3">3º trimestre</option>
      <option value="4">4º trimestre</option>
      <option value="">Todos</option>
    </SelectQuarter>
  );
};

export default SelectQuarterComponent;
