import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 15px 0px;
  width: 90%;
  padding: 15px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;

  font-size: 1rem;

  button {
    margin-top: 15px;
  }
  span + span {
    margin-top: 0px;
  }
  span {
    margin: 20px 0;
    div {
      width: 100%;
    }
    button {
      margin-top: 0px;
    }
  }
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 0px;
`;
export const SubTitle = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 10px;
  margin-bottom: 20px;
`;

export const Select = styled('select')<{ error: boolean }>`
  border-radius: 5px;

  height: 45px;
  padding: 15px;

  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  background: #fcfcfc;
  color: rgba(0, 0, 0, 0.5);
  ${props => props.error && 'border: 1px solid #DF0A24;'}
`;

export const ErroAlert = styled.p`
  margin-top: 4px;
  color: #df0a24;
  font-size: 10px;
  min-height: 16px;
  margin-top: -20px;
`;

export const TextArea = styled.textarea`
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  background: #fcfcfc;
  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  color: rgba(0, 0, 0, 0.5);
`;

export const ResultSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;
`;

export const SelectYear = styled('select')`
  border-radius: 10px;
  height: 45px;
  padding: 8px;
  width: 150px;
  border: solid 1px;
  background: #fcfcfc;
  color: #1d5a75;
  color: rgba(0, 0, 0, 0.5);
`;

export const ResultTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  opacity: 0.5;

  &.active {
    opacity: 1;
    color: #1d5a75;
  }
`;

export const SelectQuarter = styled('select')`
  border-radius: 10px;
  height: 45px;
  padding: 8px;
  width: 150px;
  border: solid 1px;
  background: #fcfcfc;
  color: #1d5a75;
  color: rgba(0, 0, 0, 0.5);
`;
