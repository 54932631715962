import styled from 'styled-components';

export const Container = styled.main`
  display: flex;
  flex-direction: column;
  max-height: 80vh;
  overflow: auto;
`;

export const Content = styled.div`
  margin-top: 3%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1vh;

  svg {
    margin-bottom: 10px;
  }
  button {
    margin-top: 20px;
  }
  p {
    font-size: 14px;
    width: 100%;
    word-wrap: break-word;
    text-align: center;
  }
`;

export const TitleModal = styled.div`
  b {
    font-size: 20px;
  }
  svg {
    color: #488cfa;
  }
  display: flex;
  justify-content: right;
  margin-bottom: -20px;
`;

export const Line = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  width: 100%;
  margin: 5px 0;
  p {
    width: fit-content;
    margin: 0px;
  }
`;

export const Title = styled.b`
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  margin-bottom: 20px;
`;
