import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  padding: 10px 10px 0px 9px;
`;

export const Button = styled.button`
  padding: 10px;
  display: flex;
  align-items: center;
  font-size: 20px;
`;
