import { api } from './api';
import {
  CountFrequenciesResponseDTO,
  ListFrequencyReportResponseDTO,
} from './dtos/frequencyServiceDTO';

interface IFrequency {
  date: string | any;
  pastor_present: boolean;
  tithes: string;
  offers: string;
  comments: string;
  quarter_number: number;
  lesson_number: number;
  is_out_of_date: boolean;
  out_of_date_justification?: string;
  classes: {
    id: string;
    attendance: string;
  }[];
}

class FrequencyService {
  async listAllClasses(): Promise<any> {
    const response = await api.get(`/bs/classes`);

    return response.data;
  }

  async listMyClasses(): Promise<any> {
    const response = await api.get(`/bs/classes/churchs/me`);

    return response.data;
  }

  async CreateFrequency(data: IFrequency): Promise<any> {
    const response = await api.post(`/bs/frequencies`, data);
    return response.data;
  }

  async EditClasses(data: number[]): Promise<any> {
    const response = await api.patch(`/bs/classes/churchs/me`, {
      classes_ids: data,
    });

    return response.data;
  }

  async getLesson(): Promise<any> {
    const response = await api.get(`/bs/frequencies/quarter-lesson`);

    return response.data;
  }

  async ListFrequencyReport(
    year?: string,
    quarter?: string,
  ): Promise<ListFrequencyReportResponseDTO[]> {
    const response = await api.get<ListFrequencyReportResponseDTO[]>(
      '/bs/frequencies/reports/me',
      {
        params: { year, quarter },
      },
    );

    return response.data;
  }

  async ListFrequency(year?: string, quarter?: string): Promise<any> {
    const response = await api.get(`/bs/frequencies/me`, {
      params: { year, quarter },
    });

    return response.data;
  }

  async CountFrequencies(year: string): Promise<CountFrequenciesResponseDTO> {
    const response = await api.get<CountFrequenciesResponseDTO>(
      `/bs/frequencies/count/me`,
      {
        params: { year },
      },
    );

    return response.data;
  }
}

export default new FrequencyService();
