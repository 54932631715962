import logoEbd from '~/assets/LogoEBD.png';
import { Container, Img, LoadingCircle } from './styles';

const Loading: React.FC = () => {
  return (
    <Container>
      <LoadingCircle />
      <Img src={logoEbd} alt="logo da EBD" />
    </Container>
  );
};

export default Loading;
