import { api } from './api';

interface IUpdateRequest {
  year: number;
  quarter: number;
  classesIds: number[];
}

interface IUpdateLeaderRequest {
  year: number;
  quarter: number;
  oldLeaderId: number | null;
  newLeaderId: number | null;
  oldLeaderEndedAt: string | null;
  newLeaderBeginedAt: string | null;
}

class UpdateService {
  async CreateRequestUpdate({
    year,
    quarter,
    classesIds,
  }: IUpdateRequest): Promise<any> {
    const response = await api.post(`/bs/update-classes-request`, {
      year,
      quarter,
      classes_ids: classesIds,
    });

    return response.data;
  }

  async CreateRequestLeaderUpdate({
    year,
    quarter,
    oldLeaderId,
    newLeaderId,
    oldLeaderEndedAt,
    newLeaderBeginedAt,
  }: IUpdateLeaderRequest): Promise<any> {
    const response = await api.post(`/bs/update-leader-request`, {
      year,
      quarter,
      old_leader_id: oldLeaderId,
      new_leader_id: newLeaderId,
      old_leader_ended_at: oldLeaderEndedAt,
      new_leader_begined_at: newLeaderBeginedAt,
    });

    return response.data;
  }

  async ListLeaders(): Promise<any> {
    const response = await api.get(`/bs/leaders`);

    return response.data;
  }
}

export default new UpdateService();
