import React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  YAxis,
  XAxis,
  Tooltip,
  Legend,
  Bar,
} from 'recharts';
import { format } from 'date-fns';
import { ListFrequencyReportResponseDTO } from '~/services/dtos/frequencyServiceDTO';

interface ChartComponentProps {
  dataSource: ListFrequencyReportResponseDTO[];
}

const ChartComponent: React.FC<ChartComponentProps> = ({ dataSource }) => {
  return (
    <ResponsiveContainer
      width="100%"
      height={240}
      style={{ marginTop: '20px' }}
    >
      <BarChart width={100} height={40} data={dataSource}>
        <CartesianGrid strokeDasharray="3 3" />
        <YAxis
          dataKey="totalPresence"
          stroke="#888"
          axisLine={false}
          tickLine={false}
          width={20}
          fontSize={10}
        />
        <XAxis
          dataKey="date"
          width={20}
          fontSize={10}
          axisLine={false}
          tickLine={false}
          tickFormatter={date => format(new Date(date), 'dd/MM/yyyy')}
        />
        <Tooltip />
        <Legend />
        <Bar dataKey="totalPresence" fill="#1D5A75" name="Total de presença" />
      </BarChart>
    </ResponsiveContainer>
  );
};

export default ChartComponent;
