import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;

  background-color: #ffffff;
  z-index: 997;

  display: flex;
  align-items: center;
  justify-content: center;
`;

export const LoadingCircle = styled.i`
  height: 8vh;
  width: 8vh;

  border: 2px solid #cccccc;
  border-top-color: #fed032;
  border-radius: 50%;

  animation: linear rotation 2s infinite;

  @keyframes rotation {
    to {
      transform: rotate(1turn);
    }
  }
`;

export const Img = styled.img`
  position: absolute;
  width: 4vh;
  height: auto;
`;
