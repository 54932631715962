import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f3f3f3;
  min-height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  margin: 15px 0px;
  width: 90%;
  padding: 15px;

  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 2px;

  font-size: 1rem;

  button {
    margin-top: 15px;
  }
  span + span {
    margin-top: 0px;
  }
  span {
    margin: 20px 0;
    div {
      width: 100%;
    }
    button {
      margin-top: 0px;
    }
  }
`;

export const Title = styled.h1`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 0px;
`;

export const Headline = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 20px;
  margin-bottom: 0px;
`;

export const SubTitle = styled.h4`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  margin-top: 10px;
  margin-bottom: 20px;
`;

export const Percentage = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  font-size: 30px;

  margin-top: 20px;
  margin-bottom: 10px;
`;

export const ErroAlert = styled.p`
  margin-top: 4px;
  color: #df0a24;
  font-size: 10px;
  min-height: 16px;
  margin-top: -20px;
`;

export const TextArea = styled.textarea`
  border-radius: 5px;
  width: 100%;
  padding: 15px;
  background: #fcfcfc;
  border: none;
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.13);
  color: rgba(0, 0, 0, 0.5);
`;

export const ResultSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 10px;
  border-bottom: 1px solid #ccc;
  padding-bottom: 20px;

  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const ResultTitle = styled.h4`
  font-weight: bolder;
  margin-top: 40px;
`;

export const ResultTipes = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  border: solid 1px;
  border-radius: 10px;
  width: 150px;
  height: 45px;
  padding: 8px;
`;

export const ResultTag = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  opacity: 0.8;

  &.active {
    opacity: 1;
    color: #1d5a75;
  }
`;

export const Divider = styled.div`
  width: 1px;
  height: 100%;
  background-color: #ccc;
`;

export const ResultTipesWithDivider = styled(ResultTipes)`
  display: flex;
  justify-content: space-between;
  color: rgba(0, 0, 0, 0.5);
`;

export const GraficContent = styled.div`
  border: 1px solid #888;
  border-radius: 10px;
`;

export const SelectTypeResultContainer = styled.div`
  padding-top: 10px;
  padding-left: 5px;

  @media (min-width: 420px) {
    margin: auto;
  }
`;
