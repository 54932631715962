/* eslint-disable react/jsx-props-no-spreading */
import { Input } from '~/components';
import { IPropsInput } from '~/components/Input';
import { Container, DivList, ListContent } from './styles';

interface IProps extends IPropsInput {
  lista: { id: number; name: string }[];
  onClickList: (item: any) => void;
  valueInput: string;
  currentValue: string;
}

const InputSearch: React.FC<IProps> = ({
  lista,
  onClickList,
  valueInput,
  currentValue,
  ...rest
}: IProps) => {
  return (
    <Container>
      <Input id="input" value={valueInput} {...rest} />
      {valueInput.length > 2 && valueInput !== currentValue && (
        <>
          <DivList>
            {lista
              .filter(
                (list: any) =>
                  list.name.toLowerCase().indexOf(valueInput.toLowerCase()) >
                  -1,
              )
              .map((item: any) => (
                <ListContent
                  key={item.id}
                  onClick={() => {
                    onClickList(item);
                  }}
                >
                  {item.name}
                </ListContent>
              ))}
          </DivList>
        </>
      )}
    </Container>
  );
};

export default InputSearch;
