import styled from 'styled-components';

export const Page = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #f3f3f3;
  align-items: center;

  h1 {
    margin: 15px 0;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 92vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  button {
    margin-top: 15px;
  }
  a {
    font-weight: 700;
    text-decoration: underline;
  }

  @media (max-width: 480px) {
    width: 80%;
  }
  @media (min-width: 481px) {
    width: 50%;
  }
`;

export const Title = styled.span`
  font-weight: 700;
  font-size: 20px;
  color: #323232;
  align-self: start;
`;
